import React from "react";
import { Helmet } from "react-helmet";

import { Badge, Col, Container, Row } from "react-bootstrap";
import "./styles.css";

const IndexPage = () => {
  const technologyStack = [
    "Typescript",
    "React",
    "React Native",
    "NestJS",
    "Apollo",
    "GraphQL",
    "PostgreSQL",
    "PostGIS",
    "GIS",
    "QGIS",
    "Gatsby",
    "Strapi",
    "Docker / AWS",
    "Python",
  ];

  const emailAddress = () => (
    <a
      className="cryptedmail"
      data-name="marakic"
      data-domain="gmail"
      data-tld="com"
    >
      {}
    </a>
  );

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marko Rakic</title>
        <link rel="canonical" href="https://www.markorakic.com" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:300"
          rel="stylesheet"
        />
        <meta
          name="description"
          content="Hello and welcome to my personal webpage."
        />
      </Helmet>

      <Row style={{ height: "inherit" }}>
        <Col xs={12} style={{ height: "inherit", alignContent: "center" }}>
          <div className="content">
            {/* About me */}
            <Row>
              <Col xs={12}>
                <p>
                  Hello, my name is Marko Rakic, I am an experienced Full Stack
                  web developer. I specialize in crafting web applications
                  centered around business solutions and processes. I am
                  currently working with D19N, a New York-based company with a
                  strong presence in the telecommunications industry, servicing
                  companies like{" "}
                  <a href="https://www.youfibre.com" target="_blank">
                    YouFibre
                  </a>{" "}
                  and{" "}
                  <a href="https://www.netomnia.com" target="_blank">
                    Netomnia
                  </a>
                  . I also run a significant part of{" "}
                  <a href="https://streetseventeen.com" target="_blank">
                    Street 17
                  </a>
                  , a tech company based in Tallinn, Estonia. My primary focus
                  is currently on front-end development, guided by robust design
                  principles and essential UX concepts. I am an avid learner,
                  constantly picking up new skills. Feel free to visit my{" "}
                  <a href="https://github.com/marko-s17" target="_blank">
                    GitHub
                  </a>{" "}
                  profile, or contact me at {emailAddress()} anytime.
                </p>
              </Col>
              <Col xs={12} className="mt-4">
                <p>The tools I work with:</p>
              </Col>
            </Row>

            {/* Technology Stack */}
            <Row className="pt-4">
              <Col xs={12}>
                {technologyStack.map((tech, i) => (
                  <Badge
                    pill
                    className="techPill mb-3"
                    style={{
                      marginRight: i < technologyStack.length - 1 ? 8 : 0,
                    }}
                  >
                    {tech}
                  </Badge>
                ))}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default IndexPage;
